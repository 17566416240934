.video-menu-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.video-menu {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.section {
    border-bottom: 1px solid #eee;
}

.section-header {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    background-color: #f2f2f2;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
}

.section-icon {
    margin-right: 10px;
    font-size: 14px;
}

.section-content {
    padding-left: 15px;
}

.subsection {
    border-bottom: 1px solid #eee;
}

.subsection-header {
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 500;
}

.subsection-content {
    padding-left: 15px;
}

.video-item {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.2s;
}

.video-item:hover {
    background-color: #e9e9e9;
}

.play-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #333;
    color: white;
    margin-right: 10px;
    font-size: 12px;
}

.video-title {
    font-size: 14px;
    text-decoration: underline;
}

/* Modal Styles */
.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.video-modal {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow: auto;
    padding: 0;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.modal-header h3 {
    margin: 0;
    font-size: 18px;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.video-container {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
    border: none; /* Remove any iframe border */
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-description {
    padding: 15px;
}

.video-description h4 {
    margin: 0 0 10px 0;
}

.video-description p {
    margin: 0;
    color: #666;
    font-size: 14px;
}
